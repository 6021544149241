var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.addNewUserSidebar)?_c('add-new-user',{attrs:{"add-new-user-sidebar":_vm.addNewUserSidebar},on:{"close-sidebar":function($event){_vm.addNewUserSidebar = false}}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"mt-1"},[_c('h3',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountMultipleOutline)+" ")]),_vm._v(_vm._s(_vm.serverConfig.name)+" Users ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.addNewUserSidebar = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountPlus)+" ")]),_vm._v(" New User ")],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.userListHeaders,"items":_vm.appUsers,"items-per-page":25,"footer-props":{'items-per-page-options': [10, 25, 50, 100, -1]}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('small',[_vm._v(_vm._s(item.email))])])],1)]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"30","color":"primary"}},[_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v(" "+_vm._s(item.enabled ? _vm.icons.mdiCheckOutline : null)+" ")])],1)],1)]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [(item.lastLogin)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.lastLogin,"from")))])])]):_vm._e()]}},{key:"item.waitingOnAccountCreation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"30","color":"primary"}},[_c('v-icon',{attrs:{"size":"18","color":"primary"}},[_vm._v(" "+_vm._s(item.waitingOnAccountCreation ? _vm.icons.mdiCheckOutline : null)+" ")])],1)],1)]}},{key:"item.snowflakeUsername",fn:function(ref){
var item = ref.item;
return [(item.snowflakeCredentials && item.snowflakeCredentials.username)?_c('div',{staticClass:"d-flex align-center"},[_c('span',[_c('v-icon',{attrs:{"color":"info","left":""}},[_vm._v(_vm._s(_vm.icons.mdiSnowflake))]),_vm._v(_vm._s(item.snowflakeCredentials.username))],1)]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.console.log(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Create "+_vm._s(_vm.serverConfig.name)+" User")])],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Disable User")])],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }