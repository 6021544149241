/* eslint-disable lines-around-comment */
// import { buildRoleHierarchy } from '@/snowflake/snowflakeAdministration'
// import _ from 'lodash'
// import { functions, httpsCallable } from '@/firebaseApp'
// import store from './store'
// import { writeDoc } from '@/firestore'
// import { snowflakeQuery } from '@/snowflake'
import { cloudFunction } from './functions'

// const account = store.state.accountProfile || JSON.parse(localStorage.getItem('accountProfile'))

export default async function customCode() {
  const cloudFunctionData = {
    functionName: 'googleSheetStartEgressExecution',
    payload: { refreshToken: '1//042nOjPBQP5gYCgYIARAAGAQSNwF-L9IrCr5nndLZDjMSJzGtHK-W-WxbScyve_9dd298t92jdlr4cUZKwVHxERmi8WGF-8h7m2w',
      workbookId: '1AU9Gakl7xl9xfLNXA-dL3JkBu3PFwv3NlXTIIG7J6ek',
      sheetId: '342531267',
      workbookName: 'SnowFlow Test3',
      sheetName: 'Snowflow Test Sheet',
      updateType: 'overwrite',
      query: 'select * from external_share.hubspot.hubspot_companies limit 20',
    },
  }
  const response = await cloudFunction(cloudFunctionData)
  console.log('Response', response)

  return response
}
// Run SnowBindingPipeline
// const response = await cloudFunction({
//   functionName: 'pubSubExecution',
//   payload: { topic: 'snowBindingMainExecution-topic', data: { accountId: 1, bindingId: '1660236008551' } }, // 1660236008551 - Opp, // 1656437522779 - Companies
// })
// console.log('Response', response)

// return response

// Encrypt Data
// const text = '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'
// const response = await cloudFunction({ functionName: 'encryptData', payload: { text } })
// console.log('Response', response)

// return response
// const query = 'select * from external_share.hubspot.hubspot_companies where company_id = 8691715;'
// const snowflakeResponse = await snowflakeQuery({ query })
// const data = snowflakeResponse.rows

// const connector = { auth: { accessToken: { content: 'bb5cf5a807eea2913658add9a81c0bdfb20a5ae23d05899adab277d8ddb92c5453217963cca87c6264dd50a7', iv: 'a6e08e94dc65e0a8644f4546b419c1b5' } } }
// const sendToFunction = httpsCallable(functions, 'snowBindingsMainExecution')
// const payload = {
//   account: store.state.accountProfile,
//   bindingId: '1656437522779',
// }
// const response = await sendToFunction(payload)
// console.log('Response', response)

//   const response = await cloudFunction({
//     functionName: 'snowBindingsMainExecution',
//     payload: { bindingId: '1656437522779' },
//   })
//   console.log('Response', response)
// }

// const response = await readDoc({ collection: 'snowflakeGrants', docId: 'ANALYST_ROLE' })
// console.log('Response', response)
// const response = await cloudFunction({
//   functionName: 'refreshRoleGrants',
// })
// console.log('Response', response)

// const roles = buildRoleHierarchy()
// console.log('roles', roles)

// SAVED QUERIES BELOW
// ///////////////////

// Calling a Cloud Function - payload: { functionName, payload: { account } }
// const sendToFunction = httpsCallable(functions, 'callFunction')
// const payload = { account: store.state.accountProfile }
// const response = await sendToFunction({
//   functionName: 'schemaData',
//   payload,
// })
// console.log('Response', response)

// Historical Consumption Data Loads | payload: { account }
// const sendToFunction = httpsCallable(functions, 'callFunction')
// const payload = { account: store.state.accountProfile, type: 'historical' }
// const response = await sendToFunction({functionName: 'consumptionData', payload })
// console.log('RESPONSE: ', response)
// return response
