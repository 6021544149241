<template>
  <div>
    <v-navigation-drawer
      v-model="addNewUserSidebarDrawer"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center mb-4">
          <span class="font-weight-semibold text-base text--primary">Add New {{ serverConfig.name }} User</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="resetUserData"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              v-model="userData.firstName"
              outlined
              dense
              :rules="[validators.required]"
              label="First Name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.lastName"
              outlined
              dense
              :rules="[validators.required]"
              label="Last Name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <!-- <v-checkbox
              v-if="userData.snowflakeUsername"
              v-model="userData.requireOauth"
              class="mb-5 pb-5"
              label="Require Oauth Access"
              hint="Requires User to Login Using Snowflake Oauth Access"
              persistent-hint
            >
            </v-checkbox> -->

            <v-text-field
              v-model="userData.email"
              :rules="[validators.required,validators.emailValidator]"
              outlined
              dense
              type="email"
              label="Email"
              placeholder="Email"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-select
              v-model="userData.role"
              :rules="[validators.required]"
              :label="serverConfig.name + ' Role'"
              :items="roleOptions"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            >
            </v-select>

            <v-alert
              text
              height="25px"
              class="pa-0 pl-3"
            >
              <strong>Snowflake Settings</strong>
            </v-alert>

            <v-text-field
              v-model="userData.snowflakeCredentials.username"
              :rules="account.snowflakeOauth && account.snowflakeOauth.oauthRequired ? [validators.required] : []"
              outlined
              dense
              label="Snowflake Username"
              hide-details="auto"
              class="mb-6"
              hint="Username Required IF Using Snowflake Oauth"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="userData.snowflakeCredentials.role"
              outlined
              dense
              label="Snowflake Role"
              hide-details="auto"
              class="mb-6"
              hint="If not supplied, we will use users default role assigned in Snowflake"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="userData.snowflakeCredentials.warehouse"
              outlined
              dense
              label="Snowflake Warehouse"
              hide-details="auto"
              class="mb-6"
              hint="If not supplied, we will use users default warehouse assigned in Snowflake"
              persistent-hint
            ></v-text-field>

            <v-select
              v-model="linkType"
              outlined
              label="Link Type"
              :items="linkTypes"
              hint="What links should be provided in the welcome email?"
              persistent-hint
            >
            </v-select>

            <v-alert
              v-if="duplicateEmailError"
              type="error"
              text
            >
              Email Already Exists
            </v-alert>

            <v-btn
              color="primary"
              class="me-3"
              type="submit"
            >
              Add
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetUserData"
            >
              Cancel
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import serverConfig from '@serverConfig'
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, watchEffect } from '@vue/composition-api'

export default {
  props: {
    addNewUserSidebar: {
      type: Boolean,
      required: true,
    },
    userDataForAppUser: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const addNewUserSidebarDrawer = ref(true)
    const duplicateEmailError = ref(false)
    const linkType = ref('Password Link')
    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      role: null,
      snowflakeCredentials: { username: null, role: null, warehouse: null },
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
      duplicateEmailError.value = false
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('close-sidebar')
    }
    onMounted(() => {
      console.log('ON MOUNTED')

      // Check if coming from Snowflake User and Pre-Populate Data
      if (props.userDataForAppUser.loginName) {
        console.log('LOADING DATA....')
        const snowflakeUserData = props.userDataForAppUser
        userData.value.firstName = snowflakeUserData.firstName
        userData.value.lastName = snowflakeUserData.lastName
        userData.value.email = snowflakeUserData.email
        userData.value.snowflakeCredentials.username = snowflakeUserData.loginName
        userData.value.snowflakeCredentials.role = snowflakeUserData.defaultRole
        userData.value.snowflakeCredentials.warehouse = snowflakeUserData.defaultWarehouse
      }
    })

    const account = computed(() => store.state.accountProfile)

    const roles = computed(() => store.state.users.appRoles)

    const roleOptions = computed(() => roles.value.map(m => ({ text: m.name, value: m.id })))

    const appUsers = computed(() => {
      const getUsers = store.state.users.appUsers

      const users = getUsers.map(m => ({
        ...m,
        role: roles.value.length > 0 ? roles.value.filter(f => f.id.toString() === m.role.toString())[0].name : null,
      }))

      return users
    })

    const linkTypes = computed(() => {
      if (account.value.snowflakeOauth?.oauthRequired) {
        linkType.value = 'Snowflake Oauth Link'

        return ['Snowflake Oauth Link']
      }
      if (!userData.value.snowflakeCredentials.username) {
        return ['Password Link']
      }

      return ['Password Link', 'Snowflake Oauth Link']
    })

    watchEffect(() => {
      if (!addNewUserSidebarDrawer.value) {
        resetUserData()
        addNewUserSidebarDrawer.value = true
      }
    })

    const onSubmit = async () => {
      duplicateEmailError.value = false
      userData.value.linkType = linkType.value
      console.log('appUsers', appUsers)
      const activeUsers = appUsers.value.filter(f => !f.deleted).map(m => m.email)
      if (valid.value) {
        console.log('REACHED INSIDE SUCCESFUL')

        // If Email (Not Deleted) Already Exists
        if (activeUsers.includes(userData.value.email)) {
          duplicateEmailError.value = true

          return
        }

        // Force snowflakeUsername to UPPER if exists to properly lookup later
        if (userData.value.snowflakeUsername) {
          userData.value.snowflakeUsername = userData.value.snowflakeUsername.toUpperCase()
          if (userData.value.requireOauth) userData.value.sfid = account.sfid
        }

        store.dispatch('users/createTempUserProfile', userData.value).then(() => {
          emit('close-sidebar')
          store.dispatch('systemMessages', {
            type: 'success',
            message: 'User creation successful. Waiting for user to join.',
            light: true,
          })
        })

        resetUserData()
      } else {
        validate()
      }
    }

    return {
      serverConfig,
      roleOptions,
      appUsers,
      account,
      linkType,
      linkTypes,
      addNewUserSidebarDrawer,
      duplicateEmailError,
      resetUserData,
      form,
      onSubmit,
      userData,
      valid,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
