<template>
  <div>
    <v-tabs
      v-model="currentTab"
      icons-and-text
      grow
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        style="text-transform: none !important;"
        @click="tab.type === 'server' ? serverQuery(tab.name) : localQuery(tab.name)"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="i in tabs.length"
        :key="i"
      >
        <v-card
          flat
          class="ma-10"
        >
          <div>
            <v-row>
              <v-col
                sm="12"
                align="end"
              >
                <v-btn
                  v-if="queryString"
                  @click="copyQuery()"
                >
                  Copy Text
                </v-btn>
              </v-col>
              <v-col>
                <v-progress-circular
                  v-if="loading"
                  :width="3"
                  color="red"
                  indeterminate
                ></v-progress-circular>
                <span style="white-space: pre-line">{{ queryString }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { cloudFunction } from '@/functions'
import store from '@/store'
import {} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { createSnowflakeProcFindTableChanges, createSnowflakeProcLoadParquetData, createSnowflakeProcRetrieveSnowBindingLogObjects } from '../../functions/snowflakeProcedures'

export default {
  setup() {
    const currentTab = ref(0)
    const account = computed(() => store.state.accountProfile)
    const accountSettings = computed(() => store.state.accountSettings)
    const schema = computed(() => `${account.value.snowflakeSettings.ownershipSchemaDatabase}.${account.value.snowflakeSettings.ownershipSchema}`)
    const queryString = ref(null)
    const loading = ref(false)

    const tabs = [
      { name: 'createTableFreshnessRefreshProcedureQuery', type: 'server' },
      { name: 'createTableFreshnessRefreshTaskQuery', type: 'server' },
      { name: 'queryHistoryBackfillProcedure', type: 'server' },
      { name: 'createSnowflakeProcFindTableChanges', type: 'local' },
      { name: 'createSnowflakeProcRetrieveSnowBindingLogObjects', type: 'local' },
      { name: 'createSnowflakeProcLoadParquetData', type: 'local' },
    ]

    const copyQuery = async () => {
      await navigator.clipboard.writeText(queryString.value)
    }

    const serverQuery = async queryName => {
      loading.value = true
      queryString.value = null
      const payload = { schema: schema.value, queryName, accountSettings: accountSettings.value }
      const snowflakeQueryString = await cloudFunction({ functionName: 'superAdminSnowflakeQueries', payload })
      console.log('Returned', snowflakeQueryString.data)
      queryString.value = snowflakeQueryString.data
      loading.value = false
    }

    const localQuery = async queryName => {
      if (queryName === 'createSnowflakeProcFindTableChanges') queryString.value = createSnowflakeProcFindTableChanges(schema.value)
      if (queryName === 'createSnowflakeProcRetrieveSnowBindingLogObjects') queryString.value = createSnowflakeProcRetrieveSnowBindingLogObjects(schema.value)
      if (queryName === 'createSnowflakeProcLoadParquetData') queryString.value = createSnowflakeProcLoadParquetData(schema.value)
    }

    return { currentTab, queryString, loading, tabs, schema, serverQuery, localQuery, copyQuery }
  },
}
</script>
