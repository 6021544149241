<template>
  <div>
    <v-btn
      color="primary"
      class="ma-5"
      @click="runCustomCode()"
    >
      Test Local Code
    </v-btn>
    <v-btn
      color="primary"
      class="ma-5"
      @click="runTestCloudFunction()"
    >
      Test Cloud Function
    </v-btn>
    <v-btn
      class="ma-5"
      color="error"
      @click="queryHistoryLoad()"
    >
      Query History Load
    </v-btn>
    <v-btn
      class="ma-5"
      color="primary"
      @click="showSnowflakeExecutableQueries()"
    >
      Show Executable Snowflake Queries
    </v-btn>
    <super-admin-snowflake-queries v-if="showing === 'snowflakeExecutableQueries'"></super-admin-snowflake-queries>
  </div>
</template>
<script>
import customCode from '@/customCode'
import { cloudFunction } from '@/functions'
import { executeQueryHistoryBackfill } from '@/snowflake/snowflakeAdministration'
import { ref } from '@vue/composition-api'
import SuperAdminSnowflakeQueries from './SuperAdminSnowflakeQueries.vue'

export default {
  components: { SuperAdminSnowflakeQueries },
  setup() {
    const showing = ref(null)
    const snowflakeQueriesTab = ref(null)
    const runCustomCode = async () => {
      showing.value = null
      console.log('Custom Code Beginning...')
      try {
        await customCode()
        console.log('Custom Code Finished Successfully...')
      } catch (err) {
        console.log(`Custom Code Failed: ${err}`)
      }
    }
    const runTestCloudFunction = async () => {
      showing.value = null
      console.log('Attempting to Run Cloud Function Test Code ...')
      try {
        const response = await cloudFunction({ functionName: 'testCode' })
        console.log('Response:')
        console.log(response)
        console.log('Finished Cloud Function Test')
      } catch (err) {
        console.log(`Custom Code Failed: ${err}`)
      }
    }

    const queryHistoryLoad = async () => {
      showing.value = null
      const startBeforeTimestamp = new Date(new Date().toDateString())
      startBeforeTimestamp.setUTCMonth(0)
      startBeforeTimestamp.setUTCDate(0)
      startBeforeTimestamp.setFullYear(startBeforeTimestamp.getFullYear() - 3)
      await executeQueryHistoryBackfill(startBeforeTimestamp)
    }

    // ————————————————————————————————————
    //* ——— Gets Parsed Query Strings For Executable Queries/Procedures/Tasks/Etc
    // ————————————————————————————————————
    const showSnowflakeExecutableQueries = async () => {
      showing.value = 'snowflakeExecutableQueries'
    }

    return { showing, snowflakeQueriesTab, runTestCloudFunction, queryHistoryLoad, runCustomCode, showSnowflakeExecutableQueries }
  },
}
</script>
