<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-card-text>
            <h3 class="primary--text">
              <v-icon
                left
                color="primary"
              >
                {{ icons.mdiCog }}
              </v-icon>{{ serverConfig.name }} Admin Panel
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs
      v-model="currentTab"
      icons-and-text
      grow
    >
      <v-tab>
        Users
        <v-icon>{{ icons.mdiAccountMultipleOutline }}</v-icon>
      </v-tab>

      <v-tab>
        Roles
        <v-icon>{{ icons.mdiShieldAccount }}</v-icon>
      </v-tab>

      <v-tab v-if="user.isSuperAdmin">
        Run Custom Code
        <v-icon>{{ icons.mdiCog }}</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="i in 3"
        :key="i"
      >
        <v-card flat>
          <user-list v-if="currentTab === 0"></user-list>
          <roles v-if="currentTab === 1"></roles>
          <super-admin v-if="currentTab === 2"></super-admin>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { mdiAccountMultipleOutline, mdiCog, mdiShieldAccount } from '@mdi/js'
import serverConfig from '@serverConfig'
import { computed, ref } from '@vue/composition-api'
import Roles from './Roles.vue'
import SuperAdmin from './SuperAdmin.vue'
import UserList from './UserList.vue'

export default {
  components: {
    Roles,
    UserList,
    SuperAdmin,
  },
  setup() {
    const currentTab = ref(0)
    const user = computed(() => store.state.user)

    return {
      user,
      serverConfig,
      currentTab,
      icons: {
        mdiCog,
        mdiAccountMultipleOutline,
        mdiShieldAccount,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
